#HomePage {
    min-height: 100%;
}

.mask-black-strong {
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.HomePage_Logo {
    width: 200px;
}