#CommandLogs .form-control {
    background: transparent;
    color: white;
    border: 1px solid grey;
}
  
#CommandLogs .form-control:focus {
    color: white;
    background: transparent;
    border: 1px solid orangered !important;
    box-shadow: 0 1px 0 0 orangered !important;
}

#CommandLogs .page-link:hover {
    color: #212529;
}

#CommandLogs .page-link {
    transition: .3s;
    color: white;
}

#CommandLogs .disabled .page-link {
    color: #212529;
}